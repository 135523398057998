<template>
  <div>
    <!--begin::Card-->
    <OverlayLoader :is-submitting="loader.show" :message="loader.message" :timing="loader.timing">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-lg-12">
            <div class="alert alert-info mb-5 p-5" role="alert">
              <h4 class="alert-heading">Informações importantes!</h4>
              O seu saque será realizado em até 1 dia útil.
            </div>

            <b-form class="form" @submit.stop.prevent="onSubmit">
              <h3 class="font-size-lg text-dark font-weight-bold mb-6">Informe o valor que deseja sacar:</h3>
              <!-- Value input -->
              <b-form-group>
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <money
                    v-model="$v.form.value.$model"
                    v-bind="maskMoneyReal()"
                    class="form-control form-control-solid h-auto py-5 px-6 rounded"
                    :class="$v.form.value.$invalid ? 'is-invalid' : 'is-valid'"
                    :state="validateState('value')"
                  />
                  <b-form-invalid-feedback v-if="!$v.form.value.minValue">
                    O minimo para saque é de R$ 1.00
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback v-if="!$v.form.value.validateBalanceSulficient">
                    Seu saldo é insulficiente.
                  </b-form-invalid-feedback>
                </b-input-group>
                <!-- Current Balance -->
                <small class="text-primary">
                  Saldo disponivel:
                  <strong class="text-primary font-weight-boldest">
                    {{ currentWalletReal.balance | currency() }}
                  </strong>
                </small>
              </b-form-group>

              <!-- Method -->
              <h3 class="font-size-lg text-dark font-weight-bold mb-6">
                Selecione a conta que você gostaria de receber:
              </h3>

              <div v-if="isLoading" class="font-size-xs text-dark mb-6 d-flex align-items-center">
                <b-spinner class="mr-2" variant="primary" type="grow" label="Spinning"></b-spinner>
                Carregando dados bancários..
              </div>

              <div v-if="listBankAccounts.length > 0 && !isLoading">
                <div v-for="(item, i) in listBankAccounts" :key="i" class="card card-custom mb-2 bg-dark shadow">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="mr-5">
                        <span class="font-weight-bold text-white font-size-h5">{{ item.ecosystem_bank.name }}</span>
                        <div class="justify-content-between mt-2 font-size-h7 text-white">
                          <div>
                            Titular:
                            <span class="font-weight-bold text-warning">{{ currentCustomer.name }}</span>
                          </div>
                          <div>
                            Agencia:
                            <span class="font-weight-bolder text-warning">{{ item.agency_code }}</span>
                          </div>
                          <div>
                            Conta:
                            <span class="font-weight-bold text-warning">
                              {{ item.account_number }}-{{ item.account_digit }}
                            </span>
                          </div>
                          <div>
                            Tipo:
                            <span class="font-weight-bold text-warning">Conta Corrente</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="radio-inline">
                          <label class="radio radio-outline radio-outline-2x radio-warning radio-lg">
                            <input
                              v-model="$v.form.account_id.$model"
                              :state="validateState('account_id')"
                              type="radio"
                              :value="item.id"
                            />
                            <span></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Body-->
                </div>
                <small v-if="!$v.form.account_id.required" class="text-danger">Seu saldo é insulficiente.</small>
              </div>

              <div v-if="listBankAccounts.length <= 0 && !isLoading" class="alert alert-danger" role="alert">
                <p>Ops.. Você não possuí nenhuma conta cadastrada.</p>
                <div class="border-bottom border-white opacity-20 mb-2"></div>
                <p class="mb-0">
                  Clique
                  <router-link v-slot="{ href, navigate }" custom to="/profile/bank">
                    <a :href="href" class="btn btn-primary btn-sm" @click="navigate">Aqui</a>
                  </router-link>
                  para cadastrar seus dados bancários
                </p>
              </div>

              <!--begin::Action-->
              <div class="form-group d-flex justify-content-center border-top pt-10">
                <button
                  :disabled="(listBankAccounts.length <= 0 && !isLoading) || isSubmitting"
                  :class="{ 'spinner spinner-light spinner-right': isSubmitting }"
                  type="submit"
                  class="btn btn-success font-weight-bold px-12 py-4 my-3 font-size-3 mx-4"
                >
                  Solicitar Saque
                </button>
              </div>
              <!--end::Action-->
            </b-form>
          </div>
        </div>
      </div>
    </OverlayLoader>

    <!-- <ModalPayInvoice /> -->
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
</style>

<script>
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import OverlayLoader from '@/shared/content/OverlayLoader.vue';
import { Money } from 'v-money';
// import ModalPayInvoice from '@/shared/components/invoice/ModalPayInvoice';

export default {
  name: 'NewDeposit',
  components: { Money, OverlayLoader },
  mixins: [validationMixin],
  data() {
    return {
      isSubmitting: false,
      isLoading: false,
      form: {
        value: 0,
        account_id: null
      },
      loader: {
        show: false,
        message: '',
        timing: false
      }
    };
  },
  computed: {
    ...mapGetters(['currentWalletReal', 'listBankAccounts', 'currentCustomer'])
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch('GET_BANK_ACCOUNT_LIST');
    this.form.account_id = this.listBankAccounts[0].id;
    this.isLoading = false;
  },
  validations: {
    form: {
      value: {
        required,
        minValue: minValue(1),
        validateBalanceSulficient(value) {
          return value <= this.currentWalletReal.balance;
        }
      },
      account_id: {
        required
      }
    }
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.isSubmitting = false;
        return;
      }

      // send register request
      await this.$store
        .dispatch('STORE_WITHDRAW', this.form)
        .then((response) => {
          this.$swal({
            title: response.message,
            icon: 'success'
          });

          this.$bvModal.hide('modal-new-withdraw');
          this.$store.dispatch('GET_WITHDRAW_LIST', { params: { status_id: '1,2' } });
        })
        .catch((error) => {
          this.$bvModal.hide('modal-new-withdraw');
          this.$swal({
            title: error,
            icon: 'error'
          });
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>
