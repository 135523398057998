<template>
  <b-overlay
    :show="isSubmitting"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
    :variant="variant"
    :opacity="0.92"
    z-index="0"
  >
    <slot></slot>

    <template v-if="timing" v-slot:overlay>
      <div class="text-center">
        <b-icon
          v-if="icon == true"
          class="mb-10"
          :class="variant == 'dark' ? 'text-white' : ''"
          :icon="iconName"
          font-scale="5"
          animation="cylon"
        />
        <p id="cancel-label" class="text-warning">{{ message }}</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'light'
    },
    iconAnimation: {
      type: Boolean,
      default: true
    },
    iconName: {
      type: String,
      default: 'stopwatch'
    },
    icon: {
      type: Boolean,
      default: true
    },
    isSubmitting: {
      type: Boolean,
      default: false
    },
    timing: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: null
    }
  }
};
</script>
