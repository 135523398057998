<template>
  <!--begin::Pagination-->
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <div class="d-flex flex-wrap py-2 mr-3">
      <!--begin::firt and previus buttons-->
      <a
        :class="{ disabled: pager.currentPage === 1 }"
        class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1"
        @click="setPage(1)"
      >
        <i class="ki ki-bold-double-arrow-back icon-xs"></i>
      </a>
      <a
        :class="{ disabled: pager.currentPage === 1 }"
        class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1"
        @click="setPage(pager.currentPage - 1)"
      >
        <i class="ki ki-bold-arrow-back icon-xs"></i>
      </a>
      <!--end::firt and previus buttons-->
      <!--begin::pages buttons-->
      <a
        v-for="page in pager.pages"
        :key="page"
        class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1"
        :class="{ 'btn-hover-primary active': pager.currentPage === page }"
        @click="setPage(page)"
      >
        {{ page }}
      </a>
      <!--end::pages buttons-->
      <!--begin::next and last buttons-->
      <a
        :class="{ disabled: pager.currentPage === pager.totalPages }"
        class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1"
        @click="setPage(pager.currentPage + 1)"
      >
        <i class="ki ki-bold-arrow-next icon-xs"></i>
      </a>
      <a
        :class="{ disabled: pager.currentPage === pager.totalPages }"
        class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1"
        @click="setPage(pager.totalPages)"
      >
        <i class="ki ki-bold-double-arrow-next icon-xs"></i>
      </a>
      <!--end::next and last buttons-->
    </div>

    <div class="d-flex align-items-center py-3">
      <span class="text-muted">Mostrando {{ pager.pageSize }} de {{ pager.totalItems }} registros</span>
    </div>
  </div>
  <!--end::Pagination-->
</template>

<script>
import paginate from 'jw-paginate';

const defaultLabels = {
  first: 'First',
  last: 'Last',
  previous: 'Previous',
  next: 'Next'
};

const defaultStyles = {
  ul: {
    margin: 0,
    padding: 0,
    display: 'inline-block'
  },
  li: {
    listStyle: 'none',
    display: 'inline',
    textAlign: 'center'
  },
  a: {
    cursor: 'pointer',
    padding: '6px 12px',
    display: 'block',
    float: 'left'
  }
};

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    initialPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    maxPages: {
      type: Number,
      default: 10
    },
    labels: {
      type: Object,
      default: () => defaultLabels
    },
    disableDefaultStyles: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pager: {},
      ulStyles: {},
      liStyles: {},
      aStyles: {}
    };
  },
  created() {
    if (!this.$listeners.changePage) {
      throw 'Missing required event listener: "changePage"';
    }

    // set default styles unless disabled
    if (!this.disableDefaultStyles) {
      this.ulStyles = defaultStyles.ul;
      this.liStyles = defaultStyles.li;
      this.aStyles = defaultStyles.a;
    }

    // merge custom styles with default styles
    if (this.styles) {
      this.ulStyles = { ...this.ulStyles, ...this.styles.ul };
      this.liStyles = { ...this.liStyles, ...this.styles.li };
      this.aStyles = { ...this.aStyles, ...this.styles.a };
    }

    // set page if items array isn't empty
    if (this.items && this.items.length) {
      this.setPage(this.initialPage);
    }
  },
  methods: {
    setPage(page) {
      const { items, pageSize, maxPages } = this;

      // get new pager object for specified page
      const pager = paginate(items.length, page, pageSize, maxPages);

      // get new page of items from items array
      const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

      // update pager
      this.pager = pager;

      // emit change page event to parent component
      this.$emit('changePage', pageOfItems);
    }
  }
};
</script>
