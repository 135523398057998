<template>
  <div>
    <ul class="nav nav-dark nav-bold nav-tabs nav-tabs-line" data-remember-tab="tab_id" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive('pending_and_confirm') }"
          href="#pending_and_confirm"
          @click.prevent="setActive('pending_and_confirm', '1,2')"
        >
          Pendentes e Confirmados
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive('canceled') }"
          href="#canceled"
          @click.prevent="setActive('canceled', '3,4')"
        >
          Cancelados
        </a>
      </li>
    </ul>

    <div class="table-responsive">
      <b-table
        responsive
        class="table table-head-custom table-vertical-center"
        :items="pageOfItems"
        :per-page="perPage"
        :fields="fields"
        :busy="isLoading"
        stacked="sm"
        hover
        show-empty
      >
        <!-- Empty table -->
        <template #empty>
          <div class="text-center mt-5"><i class="fa fa-2x fa-times text-danger mr-2"></i></div>
          <div class="text-center">Não existem depósitos.</div>
        </template>

        <!-- Busy table -->
        <template #table-busy>
          <div class="text-center p-5"><span class="spinner spinner-primary mb-20"></span></div>
          <div class="text-center mt-5">
            <h5>Aguarde enquanto carregamos seus depósitos.</h5>
          </div>
        </template>

        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(id)="row">
          <span class="font-weight-bolder text-info mb-0 font-size-lg">{{ row.item.id }}</span>
        </template>

        <template #cell(created_at)="row">
          <span>
            <div class="font-weight-bolder text-primary mb-0 font-size-lg">
              {{ $moment(row.item.created_at).format('DD/MM/YYYY') }}
            </div>
            <div class="text-muted">{{ $moment(row.item.created_at).format('hh:mm:ss') }}</div>
          </span>
        </template>

        <template #cell(amount)="row">
          <span>
            {{ row.item.amount | currency('R$ ') }}
          </span>
        </template>

        <template #cell(tax_amount)="row">
          <span>
            {{ row.item.tax_amount | currency('R$ ') }}
          </span>
        </template>

        <template #cell(final_amount)="row">
          <span>
            {{ row.item.final_amount | currency('R$ ') }}
          </span>
        </template>

        <template #cell(status)="row">
          <span class="badge" :class="'badge-' + row.item.status.class">{{ row.item.status.name }}</span>
        </template>

        <template #cell(payment_confirmation)="row">
          <span v-if="row.item.transaction.payment_confirmation">
            <div class="font-weight-bolder text-primary mb-0 font-size-lg">
              {{ $moment(row.item.transaction.payment_confirmation).format('DD/MM/YYYY') }}
            </div>
            <div class="text-muted">
              {{ $moment(row.item.transaction.payment_confirmation).format('hh:mm:ss') }}
            </div>
          </span>
          <span v-else><i class="ki ki-bold-more-hor"></i></span>
        </template>

        <template #cell(actions)="row">
          <div v-if="row.item.status_id == 1">
            <a
              :ref="'btn_cancel_' + row.item.id"
              class="btn btn-danger btn-sm font-weight-bolder"
              @click="cancelWithdraw(row.item.id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-white">
                <inline-svg src="/media/svg/icons/General/Trash.svg" />
              </span>
              Cancelar
            </a>
          </div>
          <div v-else>
            <a class="btn btn-info btn-sm font-weight-bolder">
              <span class="svg-icon svg-icon-md svg-icon-white">
                <inline-svg src="/media/svg/icons/Code/Info-circle.svg" />
              </span>
              Cromprovante
            </a>
          </div>
        </template>
      </b-table>
      <pagination
        v-if="!isLoading && withdrawList.length > 0 && isPagination"
        :items="withdrawList"
        :page-size="perPage"
        @changePage="onChangePage"
      ></pagination>
    </div>
  </div>
</template>

<script>
import Pagination from '@/shared/content/widgets/table/Pagination.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Pagination
  },
  props: {
    limit: {
      type: Number,
      default: null
    },
    isPagination: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoading: false,
      activeItem: 'pending_and_confirm',
      perPage: 10,
      fields: [
        { label: 'Data', key: 'created_at', sortable: true },
        { label: 'ID', key: 'id', sortable: true },
        { label: 'Valor', key: 'amount', sortable: true },
        { label: 'Taxa', key: 'tax_amount', sortable: true },
        { label: 'Pago', key: 'final_amount', sortable: true },
        { label: 'Status', key: 'status', sortable: true },
        { label: 'Pagamento', key: 'payment_confirmation', sortable: true },
        { label: 'Ações', key: 'actions' }
      ],
      pageOfItems: []
    };
  },
  computed: {
    ...mapGetters(['withdrawList'])
  },
  watch: {
    async withdrawList() {
      this.pageOfItems = await this.withdrawList;
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch('GET_WITHDRAW_LIST', { params: { status_id: '1,2' } });
    if (!this.isPagination) {
      this.pageOfItems = this.withdrawList;
    }
    this.isLoading = false;
  },
  methods: {
    async cancelWithdraw(withdraw_id) {
      this.$swal({
        title: 'Você tem certeza que deseja cancelar este depósito ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Sim, cancelar!',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$swal({
            text: 'Aguarde enquanto cancelamos o depósito...',
            icon: 'warning',
            showConfirmButton: false,
            dangerMode: true,
            allowOutsideClick: false
          });

          await this.$store
            .dispatch('CANCEL_WITHDRAW', withdraw_id)
            .then((response) => {
              this.$swal({ title: response.message, icon: 'success' });
              this.$store.dispatch('GET_WITHDRAW_LIST', { params: { status_id: '1,2' } });
            })
            .catch((error) => {
              this.$swal({
                title: error,
                icon: 'error'
              });
            });
        }
      });
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    async setActive(menuItem, status_id) {
      this.isLoading = true;
      this.activeItem = menuItem;
      await this.$store.dispatch('GET_WITHDRAW_LIST', { params: { status_id: status_id } });
      this.isLoading = false;
    }
  }
};
</script>

<style></style>
