<template>
  <div class="flex-row-fluid col-lg-9">
    <div class="card card-custom gutter-b">
      <div class="card-header py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">Meus Saques</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">Acompanhe abaixo seus saques</span>
        </h3>
        <div class="card-toolbar">
          <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
            <b-button v-b-modal.modal-new-withdraw variant="success" :disabled="withdrawIsPending">
              <span class="svg-icon svg-icon-md svg-icon-white">
                <inline-svg src="/media/svg/icons/Shopping/Barcode.svg" />
              </span>
              Novo Saque
            </b-button>
          </span>
          <b-tooltip v-if="withdrawIsPending" variant="danger" target="disabled-wrapper">
            Você tem um saque pendente, para solicitar outro é necessário concluir ou cancelar!
          </b-tooltip>
        </div>
      </div>

      <div class="card-body">
        <TableWithdrawList />
      </div>
    </div>

    <!-- Modal new Deposit -->
    <b-modal id="modal-new-withdraw" ref="modal-new-withdraw" title="Novo Saque" modal hide-footer no-stacking>
      <NewWithdraw />
    </b-modal>
  </div>
</template>

<script>
import TableWithdrawList from '@/shared/components/withdraw/TableWithdrawList';
import NewWithdraw from './NewWithdraw';
import { mapGetters } from 'vuex';

export default {
  name: 'ListDeposits',
  components: {
    TableWithdrawList,
    NewWithdraw
  },
  computed: {
    ...mapGetters(['withdrawIsPending'])
  },
  async mounted() {
    this.$bvModal.show('modal-new-receipt');
    await this.$store.dispatch('GET_WITHDRAW_PARAMS');
    this.$store.dispatch('SET_BREADCRUMB', [{ title: 'Carteiras' }, { title: 'Carteira Reais' }, { title: 'Saques' }]);
  }
};
</script>

<style></style>
